import { TimeStamp } from './Common';

export type AdminRole = '超級管理員' | '職員';

export interface AdminLoginPayload {
  email: string;
  password: string;
  token: string;
}

export interface AdminAuth extends TimeStamp {
  adminAuthId: number;
  adminId: number;
  provider: string;
  loginName: string | null;
  email: string | null;
  hash: string | null;
  uid: string | null;
  phoneNumber: string | null;
}

export type AdminStatus = '已停用' | '已啟用';

export interface Admin extends TimeStamp {
  adminId: number;
  loginName: string;
  displayName: string;
  email: string | null;
  avatar: string | null;
  notificationToken: string | null;
  role?: AdminRole;
  AdminAuths: AdminAuth[];
  uid: string;
  status: AdminStatus;
}

export interface AdminProfile {
  displayName: string;
  avatar: string;
  role?: AdminRole;
}

export interface AdminPara {
  displayName: string;
  loginName?: string;
  password?: string;
  avatar?: string;
  role?: AdminRole;
  email: string;
}

export interface AdminLoginProfile extends Admin {
  firebaseEmail: string;
}

export interface AdminLoginResponse {
  token: string;
  admin: AdminLoginProfile;
}

export enum AdminRolesMap {
  職員 = '社工',
  超級管理員 = '管理員',
}
