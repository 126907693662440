import { OptionItems } from 'app/models';

export const INFO_PATH_OPTIONS: OptionItems[] = [
  {
    label: 'Facebook / Instagram',
    value: 'Facebook / Instagram',
  },
  {
    label: '其他機構',
    value: '其他機構',
  },
  {
    label: '朋友介紹',
    value: '朋友介紹',
  },
  {
    label: '其他',
    value: '其他',
  },
];

export const PUBLISH_OPTIONS: OptionItems[] = [
  {
    label: '已發佈',
    value: 'Published',
  },
  {
    label: '尚未發佈',
    value: 'Unpublished',
  },
];

export const STATUS_OPTIONS: OptionItems[] = [
  {
    label: '可聊天',
    value: 'Active',
  },
  {
    label: '已過期',
    value: 'Expired',
  },
];

export const APPLICATION_OPTIONS: OptionItems[] = [
  {
    label: '已通過',
    value: 'Accepted',
  },
  {
    label: '處理中',
    value: 'Pending',
  },
  {
    label: '已拒絕',
    value: 'Rejected',
  },
];

export const CONSULTATION_APPLICATION_OPTIONS: OptionItems[] = [
  {
    label: '已通過',
    value: 'Accepted',
  },
  {
    label: '處理中',
    value: 'Pending',
  },
  {
    label: '已拒絕',
    value: 'Rejected',
  },
  {
    label: '已出席',
    value: 'Attended',
  },
  {
    label: '缺席',
    value: 'NoShow',
  },
];

export const EVENT_APPLICATION_OPTIONS: OptionItems[] = [
  {
    label: '已通過',
    value: 'Approved',
  },
  {
    label: '處理中',
    value: 'Pending',
  },
  {
    label: '已取消',
    value: 'Cancelled',
  },
  // {
  //   label: '已完成',
  //   value: 'Completed',
  // },
];

export const SHORT_APPLICATION_OPTIONS: OptionItems[] = [
  {
    label: '處理中',
    value: 'Pending',
  },
  {
    label: '已拒絕',
    value: 'Rejected',
  },
];

export const FORUM_REPORT_APPLICATION_OPTIONS: OptionItems[] = [
  {
    label: '處理中',
    value: 'Pending',
  },
  {
    label: '已通過',
    value: 'Accepted',
  },
  {
    label: '已拒絕',
    value: 'Rejected',
  },
];

export const WEEKDAYS_OPTIONS: OptionItems[] = [
  {
    label: '星期一',
    value: '1',
  },
  {
    label: '星期二',
    value: '2',
  },
  {
    label: '星期三',
    value: '3',
  },
  {
    label: '星期四',
    value: '4',
  },
  {
    label: '星期五',
    value: '5',
  },
  {
    label: '星期六',
    value: '6',
  },
  {
    label: '星期日',
    value: '0',
  },
];

export const ADMIN_OPTIONS: OptionItems[] = [
  {
    label: '管理員',
    value: '超級管理員',
  },
  {
    label: '社工',
    value: '職員',
  },
];

export const ADMIN_STATUS_OPTIONS: OptionItems[] = [
  {
    label: '已啟用',
    value: '已啟用',
  },
  {
    label: '已停用',
    value: '已停用',
  },
];

export const GRUOP_CHAT_REPORT_STATUS_OPTIONS: OptionItems[] = [
  { value: 'Pending', label: '處理中' },
  { value: 'Accepted', label: '已通過' },
  { value: 'Rejected', label: '已拒絕' },
];
